.command-category-list {
    position: relative;
    background : linear-gradient(135deg, rgba(77, 252, 255, .5) 0%, rgba(189, 84, 255, .5) 55.33%, rgba(255, 102, 250, .5) 100%);
    margin-top: 60px;
    width: 60%;
    border-radius: 14px;
    padding: 40px;
    box-sizing: border-box;
    z-index: 6;

    h2 {
        margin-top: 0;
        margin-bottom: 7px;
        font-weight: 900;
        font-size: 32px;
        position: relative;
        width: fit-content;
    }
    h2::after {
        position: absolute;
        content: "";
        width: 55%;
        height: 2px;
        border-radius: 2px;
        background : linear-gradient(90deg, rgba(77, 252, 255, 1) 0%, rgba(189, 84, 255, 1) 55.33%, rgba(255, 102, 250, 1) 100%);
        bottom: -8px;
        left: 0;
    }
    .category-desc {
        font-weight: 300;
        font-size: 14px;
    }
    .commands-container {
        background: black;
        border-radius: 10px;
        border: 1px solid #2c2c2c;
        padding: 20px;
        display: flex;
        gap: 15px;
        flex-direction: column;
    }
}
.command-category-list::after {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 1px;
    left: 1px;
    background: #0f0f0f;
    z-index: -2;
    border-radius: 14px;
}


@media screen and (max-width: 1249px) {
    .command-category-list {
        width: 90%;
    }
}

@media screen and (max-width: 929px) {
    .command-category-list {
        padding: 20px;
        h2 {
            font-size: 24px;
        }
        .commands-container {
            padding: 10px
        }
    }
}
