* {
    color: white;
}

.home {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    position: relative;
}

.container {
    width: 80%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 6;
}

.container .title {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 900;
    font-size: 108px;
    margin: 10px 0;
    background: -webkit-linear-gradient(-90deg,#ffffff, #575757);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 4;
}

.container .divider {
    width: 30%;
    height: 1px;
    background: linear-gradient(90deg, rgba(77, 240, 255, 0) 0%, rgba(77, 252, 255, 1) 32.61%, rgba(160, 64, 255, 1) 54.28%, rgba(214, 64, 255, 1) 73.28%, rgba(175, 77, 255, 0) 100%);
    z-index: 4;
}

.container .description {
    font-size: 20px;
    font-weight: 200;
}

.orb {
    z-index: 5;
    position: absolute;
    bottom: 40vh;
    width: 380px;
}

.grid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: url("../../Assets/Images/grid.svg");
    mix-blend-mode: soft-light;
    background-size: 80%;
}

.bottom {
    z-index: 4;
    position: absolute;
    bottom: 8vh;
    width: 874px;
}

.bottom-blur {
    z-index: 2;
    position: absolute;
    bottom: 9vh;
    width: 874px;
    opacity: .7;
    filter: blur(70px);
}

.top-gradient {
    width: 70vw;
    height: 20vh;
    position: absolute;
    top: 0;
    z-index: 2;
}

.top-gradient .bottom {
    position: absolute;
    top: 0;
    background : linear-gradient(90deg, rgba(77, 240, 255, 0) 0%, rgba(77, 252, 255, 1) 25.73%, rgba(189, 84, 255, 1) 55.33%, rgba(255, 102, 250, 1) 78.04%, rgba(255, 77, 216, 0) 100%);
    z-index: 0;
    width: 100%;
    height: 100%;
}
.top-gradient .top {
    position: absolute;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.04) 96.16%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 625px) {
    .orb {
        width: 300px;
        bottom: 44vh
    }
    .container .title {
        font-size: 72px;
    }
    .container .description {
        font-size: 16px;
    }
    .container .divider {
        width: 70%;
    }

    .top-gradient {
        width: 100vw;
        .bottom {
            background : linear-gradient(90deg, rgba(77, 252, 255, 1) 0%, rgba(189, 84, 255, 1) 55.33%, rgba(255, 102, 250, 1) 100%);
        }
    }

    .grid {
        background-size: 220%;
    }
}

@media screen and (min-width: 625px) and (max-height: 700px) {
    .orb {
        width: 270px;
        bottom: 44vh
    }
    .container .title {
        font-size: 68px;
    }
    .container .description {
        font-size: 18px;
    }
}
