.CommandCategory {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 30px;
    margin: 0;
    box-sizing: border-box;
    .header {
        padding-top: 150px;
        padding-bottom: 54px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: transparent;
        z-index: 7;
        .heading {
            margin: 0;
            font-size: 72px;
            font-weight: 900;
            background: -webkit-linear-gradient(-90deg, #f3f3f3, #7c7c7c);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            z-index: 6;
            mix-blend-mode: color-dodge;

        }
        .sub {
            margin: 0;
            font-weight: 300;
            z-index: 6;
        }
        .top-gradient-cont {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 2;
            .bottom {
                position: absolute;
                top: 0;
                background : linear-gradient(90deg, rgba(77, 252, 255, 1) 0%, rgba(189, 84, 255, 1) 55.33%, rgba(255, 102, 250, 1) 100%);
                z-index: 0;
                width: 100%;
                height: 100%;
                opacity: 1;
                filter: brightness(30%);
            }
            .top {
                position: absolute;
                top: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.04) 96.16%, rgba(0, 0, 0, 0) 100%);
                z-index: 1;
                width: 100%;
                height: 100%;
            }
        }
    }
    .header::after {
        content: "";
        position: absolute;
        top: 1px;
        left: 0;
        width: 100%;
        height: 100%;
        background : linear-gradient(90deg, rgba(77, 252, 255, 1) 0%, rgba(189, 84, 255, 1) 55.33%, rgba(255, 102, 250, 1) 100%);
        z-index: 0;
    }
}



.commands-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    z-index: 6;
    .expand-info {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        span {
            text-align: center;
        }
    }
}


@media screen and (max-width: 493px) {
    .CommandCategory {
        .header {
            .heading {
                font-size: 58px;
            }
        }
    }
}