.command {
    background : rgb(21, 21, 21);
    border: 1px solid #141414;
    height: unset;
    border-radius : 10px;
    transition: all cubic-bezier(0.87, 0, 0.13, 1);
    z-index: 6;
    overflow: clip;
    .command-header{
        display : flex;
        align-items: center;
        padding: 15px;
        z-index: -1;
        position: relative;
        background: rgb(21, 21, 21);
        border-radius : 10px;
        .command-expander {
            border: 1px solid #252525;
            background-color: #141414;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius : 10px;
            transition: all 0.2s ease;
            img {
                width: 14px;
                transition: all 0.2s ease;
            }
        }
        .command-expander:hover {
            background-color: #676767;
            img {
                transform: scale(1.1);
            }
        }
        .command-expander:active {
            background-color: #444444;
            img {
                transform: scale(0.9);
            }
        }


        .command-content {
            .command-full{
                margin-left: 10px;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 18px;
                font-weight: 500;
                line-height: 1.8;
                .command-full-option {
                    padding: 5px 10px;
                    background-color: #2d2d2d;
                    border-radius: 10px;
                    margin-right: 8px;
                    font-size: 16px;
                    box-decoration-break: clone;
                }
            }
            .command-description {
                margin-left: 10px;
                margin-top: 12px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    .command-details {
        padding: 20px;
        background: #0c0c0c;
        flex-direction: column;
        transform-origin: top;
        transform: translateY(-100%);
        transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
        z-index: -2;
        position: relative;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top: solid #141414 1px;
        h3 {
            margin: 0;
            font-weight: 900;
            font-size: 21px;
        }
        .options-list {
            margin-top: 10px;
            padding: 10px ;
            p {
                margin: 0;
            }
            .option-info {
                padding: 4px;
                display: flex;
                align-items: center;
                .option-name {
                    font-weight: bold;
                    font-size: 17px;
                    padding: 4px 12px;
                    color: black;
                    background : linear-gradient(90deg, rgba(77, 252, 255, 1) 0%, rgba(160, 64, 255, 1) 50%, rgba(214, 64, 255, 1) 100%);
                    width: fit-content;
                    border-radius: 10px;
                    margin-right: 5px;
                }
            }
        }
        .permissions-list {
            margin-top: 10px;
            padding: 10px ;
            p {
                margin: 0;
            }
            .permission-nm {
                font-weight: bold;
                font-size: 17px;
                padding: 4px 12px;
                color: black;
                background : linear-gradient(90deg, rgba(77, 252, 255, 1) 0%, rgba(160, 64, 255, 1) 50%, rgba(214, 64, 255, 1) 100%);
                width: fit-content;
                border-radius: 10px;
                margin-right: 5px;
            }
        }
    }
    .command-details.command-expanded{
        opacity: 1;
        transform: translateY(0);
    }
}
@media screen and (max-width: 1249px) {
    .command-full {
        font-size: 16px !important;
    }
    .command {
        .command-details {
            .options-list {
                .option-info {
                    flex-direction: column;
                    align-items: unset;
                    .option-name {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
